.home_banner {
  text-align: center;
  height: 300px;
  line-height: 300px;
  background: #000;
  overflow: hidden;
  .banner-item {
    // opacity: 0.9;
  }
}
