.app_layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  .app_layout_content {
    width: 100%;
    margin-top: 2px;
    position: relative;
    .app_layout_main {
      width: 1200px;
      margin: auto;
    }
  }
  .app_layout_foot {
  }
}
