.main {
  width: 100%;
  height: 100%;
  .main-left {
    height: 100%;
    float: left;
  }
  .main-content {
    float: left;
    margin-left: 10px;
    height: 100%;
  }
  .main-right {
    float: left;
    margin-left: 10px;
    height: 100%;
  }
  .main-bottom {
    display: flex;
    justify-content: flex-end;
    padding: 10px 15px;
    text-align: right;
    height: auto;
  }
}

.imgbtn_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .img {
    width: 85px;
    height: 85px;
  }

  .title {
    font-size: 18px;
    color: #010101;
    text-align: center;
    margin-top: 2px;
    font-weight: 400;
  }

  .detail {
    font-size: 14px;
    color: #757575;
    text-align: center;
    margin-top: 20px;
  }
}

.course-box {
  margin-bottom: 14px;
  &.live {
    position: relative;
  }
  .img {
    width: 223px;
    height: 168px;
    background-color: #ccc;
  }
  .big-img {
    width: 466px;
    height: 352px;
    background-color: #ccc;
  }
  .img + .title {
    width: 223px;
  }
  .big-img + .title {
    width: 466px;
  }
  .title {
    font-size: 14px;
    color: #080606;
    margin-top: 8px;
    font-weight: 400;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    display: -moz-box;
    -moz-line-clamp: 1;
    -moz-box-orient: vertical;

    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
  }
}

.teacher_box {
  display: flex;
  flex-direction: column;
  width: 100%;
  float: left;
  background-color: #e7e7e7;
  padding: 15px 10px;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .img {
      width: 80px;
      height: 80px;
      background-color: #ccc;
      margin-bottom: 10px;
    }

    .title {
      font-size: 14px;
      color: #010101;
      text-align: center;
    }

    .sub_title {
      font-size: 14px;
      font-weight: 300;
      color: #333333;
      margin-left: 10px;
      text-overflow: ellipsis;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      display: -moz-box;
      -moz-line-clamp: 1;
      -moz-box-orient: vertical;

      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
    }
  }

  .detail {
    font-size: 14px;
    color: #757575;
    text-align: center;
    text-overflow: ellipsis;
    min-height: 42px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    display: -moz-box;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;

    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
  }
}

.course-detail-header {
  background-color: #191919;
  display: flex;
  justify-content: center;
  .cd-header-content {
    width: 1200px;
    height: 100%;
    display: flex;
  }
  .course-video {
    width: 850px;
    height: auto;
  }
  .course-chapters {
    flex: 1;
    padding: 15px 10px;

    .ant-card {
      color: #ccc;
      background: #2d2d2d;
      border-radius: 4px;

      .chapter-title,
      .chapter-attach {
        color: #ccc;
      }
      .disable {
        color: #969793;
      }
    }

    .ant-card-head {
      color: #ccc;
      border-bottom: 2px solid #3c3c3c;
    }

    .ant-card-bordered {
      border: 1px solid #2d2d2d;
    }

    .cc-card-body {
      font-size: 14px;
      line-height: 18px;

      .text {
        margin-top: 5px;
        font-weight: 400;
      }

      .attach {
      }

      .price {
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
}

.course_detail_body {
  background-color: #fff;
  padding: 15px 20px;
  .course-desc {
    margin-top: 10px;
    color: #3c3c3c;
  }
}

.uc_header {
  width: 100%;
  height: 132px;
  background: linear-gradient(90deg, #2bb45c 0%, #64c354 100%);
  .uc_header_content {
    width: 1200px;
    margin: auto;
    padding: 15px 0px;
    color: white;
    display: flex;
    justify-content: space-between;
    .avatar {
    }
    .name {
      font-size: 18px;
    }
    .company {
      font-size: 18px;
    }
    .point {
      font-size: 14px;
    }
  }
}

.border_gray {
  border: 1px solid #dddddd;
}

.bg_white {
  background-color: #fff;
}

.stu_center {
  .slider {
    min-height: 200px;
    padding: 8px 0px;
    margin-left: 15px;
    border-radius: 2px;
    background-color: #fff;
    .slider_item {
      padding: 8px 15px;
      margin-bottom: 5px;
      cursor: pointer;
      &.active {
        color: #0d953d;
        border-left: #0d953d 2px solid;
      }
      &.normal {
        color: #080606;
      }
    }
  }
}

.course-cell {
  color: #080606;
  font-size: 14px;

  .course-title {
    font-size: 15px;
    font-weight: 700;
    font-size: 14px;
  }
}

.chapter-cell {
  font-size: 14px;
  margin-left: 15px;
  .chapter-title {
    font-size: 14px;
    font-weight: 500;
    color: #080606;
  }
  .chapter-attach {
    color: #080606;
    &.disable {
      color: #969793;
    }
  }
}

.cursor-p {
  cursor: pointer;
}

.order-cell {
  .order-content {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 90px;
        height: 50px;
        background-color: #ccc;
      }
      .title {
        margin-left: 10px;
        font-weight: 500;
      }
    }
    .right {
      display: flex;
      align-items: center;
    }
  }
}

.text-yellow {
  color: #ff6923;
}

.text-green {
  color: #23bb58;
}

.text-red {
  color: #ff0000;
}

.text-gray {
  color: #68686d;
}

.home-hot-panel {
  > .panel-header {
    margin-bottom: 15px;
    > .title {
      font-size: 18px;
      font-weight: 700;
      color: #333333;
    }
  }

  > .panel-body {
    margin-bottom: 15px;
    float: left;
    width: 100%;
    .course-box {
      display: inline-block;
      vertical-align: top;
      float: left;
    }
  }
}

.home-search {
  display: flex;
  width: 100%;
  justify-content: left;
  background-color: #e5eff9;
  padding: 15px 20px;
  padding-bottom: 0;
}
.category-wrap {
  margin: 20px 0;
}
.category-wrap:nth-child(1) {
  padding: 15px 20px;
}
.home-category {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  background-color: #fff9f4;
  padding: 15px 20px;

  .category-item {
    padding: 16px;
    background-color: #fff;
    margin: 0 10px;
    width: 12.5%;
    position: relative;
    cursor: pointer;

    .category-item-content {
      height: 100%;
      width: 100%;
      .category-title {
        font-size: 16px;
        font-weight: bold;
        color: #111111;
        margin-bottom: 10px;
        text-align: center;
      }
      .category-child-title {
      }
    }
  }

  .category-item:after {
    background-position: 100% 100%;
    content: ' ';
    width: 90px;
    height: 90px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .category-item:nth-child(1):after,
  .category-item:nth-child(4):after {
    //  background: url(../style/imgs/home_cate_blue.png) no-repeat 5px 5px;
  }
  .category-item:nth-child(2):after {
    // background: url(../style/imgs/home_cate_purple.png) no-repeat 5px 5px;
  }
  .category-item:nth-child(3):after {
    //  background: url(../style/imgs/home_cate_green.png) no-repeat 5px 5px;
  }

  .category-item:nth-child(1) a,
  .category-item:nth-child(1),
  .category-item:nth-child(4),
  .category-item:nth-child(4) a {
    background-color: #def9ff;
    color: #008dea;
  }
  .category-item:nth-child(2),
  .category-item:nth-child(2) a,
  .category-item:nth-child(5),
  .category-item:nth-child(5) a {
    background-color: #efedff;
    color: #6000ff;
  }
  .category-item:nth-child(6),
  .category-item:nth-child(6) a {
    background-color: #e8fffd;
    color: #1fa574;
  }
  .category-item:nth-child(7),
  .category-item:nth-child(7) a {
    background-color: #def9ff;
    color: #008dea;
  }
  .category-item:nth-child(8),
  .category-item:nth-child(8) a {
    background-color: #e8fffd;
    color: #1fa574;
  }
  .category-item:nth-child(3),
  .category-item:nth-child(3) a {
    background-color: #e8fffd;
    color: #1fa574;
  }
}
.home-category:last-child {
  padding-bottom: 15px;
}

.clear-fix {
  clear: both;
}

.tips {
  color: red;
  font-size: 12px;
}

.play-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .disable {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: #fff;
    font-size: 18px;
    left: 0;
    top: 0;
    width: 100%;
    line-height: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.expert-detail {
  .img {
    width: 150px;
    height: 150px;
  }
  .header-left {
    margin-left: 50px;
  }
  .header-right {
    margin-top: 20px;
    width: 500px;
  }
}

.live-status {
  position: absolute;
  top: 10px;
  &.unstart {
    right: 10px;
  }
  &.started {
    left: 10px;
  }
  &.finished {
    right: 10px;
  }
}

.footer {
  padding: 20px 20px;
  color: #4b4b4b;
  font-size: 14px;
}

.pdf-fullscreen {
  position: absolute;
  z-index: 100;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  background: url('./imgs/fullscreen-expand.png') no-repeat;
  background-size: 100%;
}
