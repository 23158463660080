small {
  opacity: 0.6;
}
.text-muted {
  opacity: 0.6;
}
.clear {
  display: block;
  overflow: hidden;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 16px;
  font-weight: bold;
}
.y-center {
  display: flex;
  align-items: center;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.none {
  display: none;
}
.b-white {
  border-color: #ffffff;
}
.w-full {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-v {
  height: 100vh;
}

.h-v-5 {
  height: 50vh;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.w-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}

.clear-fix {
  clear: both;
}

.c-blue {
  color: @primary-color;
}
.c-text-gray {
  color: @grey;
}

.html-reset {
  p {
    margin: 0;
  }
}

.hide {
  display: none;
}
