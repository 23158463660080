.w-8 {
  width: 8px;
  height: 8px;
  display: inline-block;
}
.w-16 {
  width: 16px;
  height: 16px;
  display: inline-block;
}
.w-20 {
  width: 20px;
  height: 20px;
  display: inline-block;
}
.w-24 {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
}
.w-32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
}
.w-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}
.w-48 {
  width: 48px;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  text-align: center;
}
.w-56 {
  width: 56px;
  height: 56px;
  line-height: 56px;
  display: inline-block;
  text-align: center;
}

.f-b {
  font-weight: bold;
}
